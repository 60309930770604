import { node, stream, multi } from '@/plugins/axios'
import saveAs from 'file-saver'
import { upperFirst } from 'lodash'
import { relativeDate } from '@/utils/date.utils.ts'
import Vue from 'vue'
export * from './summary'
import { timeAgo } from '@/utils/date.utils.ts'

const notify = params => Vue.prototype.$notify(params)

const statusTextMap = ['Queued', 'Processing', 'Complete', 'Failed']
const statusClassMap = [
  '',
  'has-text-info',
  'has-text-success',
  'has-text-danger'
]

export const getHistory = async () => {
  const { data } = await node.get('/me/multi')
  return data.map(multi => ({
    id: multi.id,
    userId: multi.userId,
    filename: multi.outputFilename,
    progress: multi.progress,
    cancelRequested: false,
    status: {
      text: statusTextMap[multi.status],
      class: statusClassMap[multi.status],
      detail: multi.error
        ? multi.error
        : multi.updatedAt
        ? `Updated ${timeAgo(multi.updatedAt)}`
        : undefined
    },
    rows: {
      total: multi.rows,
      retail: multi.rowsRetail,
      trade: multi.rowsTrade
    },
    date: {
      value: upperFirst(relativeDate(multi.createdAt)),
      relative: multi.createdAt ? timeAgo(multi.createdAt) : null
    },
    fields: multi.fieldsUsed
  }))
}

export const downloadFile = async (url, filename) => {
  return stream
    .get(url)
    .then(({ data }) => {
      const file = new window.Blob([data])
      return saveAs(file, filename)
    })
    .catch(err => {
      notify('There was an error downloading your sheet')
      throw err
    })
}

export const cancelUpload = async (id, token) => {
  const response = await multi({
    method: 'post',
    url: '/cancel',
    data: { id },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  return response
}

export const confirm = async (fileName, token) => {
  const response = await multi({
    method: 'post',
    url: '/confirm',
    data: { fileName },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  return response
}

export const getWorkbook = async (fileName, token) => {
  const response = await multi({
    method: 'post',
    url: '/workbook',
    data: { fileName },
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  return response
}
