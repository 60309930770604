export const getNewOrder = currentOrder => {
  return !currentOrder ? 'asc' : currentOrder === 'desc' ? 'asc' : 'desc'
}

export const sortRows = ({ newOrder, index, rows }) => {
  if (newOrder === 'desc')
    return rows.sort((a, b) => ('' + b[index]).localeCompare(a[index]))
  else return rows.sort((a, b) => ('' + a[index]).localeCompare(b[index]))
}

export const filterRows = ({ rows, input, selectedHeaders = [] }) => {
  const searchData = rows.filter(row => {
    if (selectedHeaders.length) {
      row = row.filter((element, index) => {
        return selectedHeaders.includes(index)
      })
    }
    return row
      .join(' ')
      .toLowerCase()
      .includes(input.toLowerCase())
  })
  return searchData
}
