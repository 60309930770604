<template>
  <div class="container">
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title is-5 has-text-info">
          <span class="icon">
            <i class="fal fa-file-times" />
          </span>
          <span>Your uploads</span>
        </p>
      </div>
      <div class="column is-narrow has-text-right">
        <button class="button is-info is-small" @click="fetch">
          <span>Refresh</span>
          <span class="icon is-size-6">
            <i
              class="fad fa-sync"
              :class="{ 'fa-pulse': $wait.is('loading') }"
            />
          </span>
        </button>
      </div>
    </div>
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <td v-for="(header, index) in tableHeaders" :key="index">
            {{ header.label }}
          </td>
        </tr>
      </thead>
      <tbody v-if="history">
        <tr
          v-for="(item, index) in history"
          :key="index"
          :class="{ disabled: item.cancelRequested }"
        >
          <td>
            <span v-if="item.progress < 100 && !item.rows.total">
              <progress
                class="progress is-link"
                :value="item.progress"
                max="100"
              />
            </span>
            <span v-else-if="item.progress === 100 || item.rows.total">
              <progress class="progress is-success" :value="item.progress" />
            </span>
            <span v-else class="has-text-grey">N/A</span>
          </td>
          <td>
            <a
              v-if="hasMultiSummary && item.status.text === 'Complete'"
              @click="goToSummary(item)"
              class="has-text-link"
            >
              {{ truncate(item.filename) }}
            </a>
            <span v-else>{{ truncate(item.filename) }}</span>
          </td>
          <td>
            <p
              :class="getStatusColour(item)"
              class="has-text-weight-bold"
              :data-test-id="`multi-history-${index}`"
            >
              {{ getStatusText(item) }}
            </p>
          </td>
          <td>
            <span :title="item.date.value" v-tippy>
              <abbr>{{ item.date.relative }}</abbr>
            </span>
          </td>
          <td>
            <div class="buttons has-addons">
              <button
                class="button"
                title="Download sheet"
                v-tippy
                @click="download({ id: item.id, filename: item.filename })"
                :class="{ 'is-loading': downloading === item.id }"
                :disabled="
                  item.status.text !== 'Complete' || downloading === item.id
                "
              >
                <span class="icon has-text-success">
                  <i class="fal fa-arrow-to-bottom" />
                </span>
              </button>
              <button
                v-if="hasMultiSummary"
                class="button"
                title="Open multi summary"
                v-tippy
                @click="goToSummary(item)"
                :class="{ 'is-loading': downloading === item.id }"
                :disabled="
                  item.status.text !== 'Complete' || downloading === item.id
                "
              >
                <span class="icon has-text-link">
                  <i class="fal fa-eye" />
                </span>
              </button>
              <button
                :disabled="item.status.text === 'Processing'"
                class="button"
                title="Re-process"
                v-tippy
                @click="$modal.open('multi/MultiReProcess', item.filename)"
              >
                <span class="icon has-text-soft">
                  <i class="fal fa-repeat"></i>
                </span>
              </button>
              <button
                class="button"
                title="View details"
                v-tippy
                @click="$modal.open('multi/MultiDetails', item)"
              >
                <span class="icon has-text-primary">
                  <i class="fal fa-info-circle" />
                </span>
              </button>
              <button
                :disabled="item.status.text !== 'Processing'"
                class="button"
                title="Cancel upload"
                v-tippy
                @click="cancelUpload(item)"
              >
                <span class="icon has-text-danger">
                  <i class="fal fa-window-close" />
                </span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-if="hasNoHistory">
      You don't have any history. Go ahead and upload a sheet.
    </p>
    <p v-else-if="!history">
      <span class="icon">
        <i class="fas fa-spinner fa-pulse" />
      </span>
    </p>
  </div>
</template>

<script>
import { cancelUpload } from '../services'
import { mapGetters, mapActions } from 'vuex'
import truncate from 'truncate'
export default {
  name: 'MultiHistoryTable',
  data: () => ({ interval: null }),
  watch: {
    history(data) {
      const processing = data.some(
        ({ status, progress }) => status.text === 'Processing' || progress < 100
      )
      const complete = data.every(
        ({ progress, status }) => status.text === 'Complete' && progress === 100
      )

      if (processing && !this.interval)
        this.interval = setInterval(() => this.fetch(), 2500)
      else if (complete && this.interval) clearInterval(this.interval)
    }
  },
  async mounted() {
    this.fetch()
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval)
  },
  computed: {
    ...mapGetters('multi', [
      'history',
      'hasNoHistory',
      'downloading',
      'isProcessing'
    ]),
    ...mapGetters('auth', ['token', 'hasMultiSummary']),
    tableHeaders() {
      return [
        { label: 'Status' },
        { label: 'Filename' },
        { label: 'Valuations' },
        { label: 'Upload date' },
        { label: '' }
      ]
    }
  },
  methods: {
    ...mapActions('multi', ['setSummaryItem', 'download', 'fetchHistory']),
    truncate(value) {
      return truncate(value, 60)
    },
    async fetch() {
      try {
        this.$wait.start('loading')
        await this.fetchHistory()
      } catch {
        this.$notify('History unavailable')
      } finally {
        this.$wait.end('loading')
      }
    },
    async cancelUpload(item) {
      item.cancelRequested = true
      await cancelUpload(item.id, this.token)
    },
    goToSummary(item) {
      if (item.filename.slice(-5) === '.xlsx') {
        this.setSummaryItem(item)
        this.$router.push({ name: 'multi-summary' }).catch(() => {})
      } else this.$notify('Unable to load sheet in Multi summary')
    },
    getStatusColour({ status, rows }) {
      const waiting = status.text === 'Processing' || status.text === 'Queued'

      if (waiting) return 'has-text-grey'
      else if (!rows.total) return 'has-text-danger'
      else return 'has-text-info'
    },
    getStatusText({ status, rows }) {
      const waiting = status.text === 'Processing' || status.text === 'Queued'
      if (waiting) return '---'
      else if (rows.total) return rows.total
      else return 0
    }
  }
}
</script>

<style lang="sass" scoped>
abbr
  border-bottom: 1px dotted #999
  cursor: help
.buttons
  &.has-addons
    flex-wrap: nowrap
</style>
